<template>
    <div id="proposal-list">



        <div id="printMeRetour" v-show="false" :key="retourSaved.lines.length">
            <div class="invoice-box" style="background-color: white; width:100%">
                <table style="width: 100%">
                    <tr class="top" style="margin-bottom: 10px">
                        <td colspan="2" style="border-bottom: 1px solid black;">
                            <table>
                                <tr>
                                    <td class="title" style="text-align: left;width:10%;margin: 0;padding:0">
                                        <img :src="src_logo" style="width:200px;height: 100px">
                                    </td>
                                    <td style="text-align: left;width:30%;margin: 0;padding:0">
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">7 RUE
                                            EL AGHALIBA LE
                                            BARDO 2000</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">Tél:70
                                            028 960-70 028
                                            970</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">Fax:71
                                            512 288</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;">
                                            E-mail:pharmaconnect@planet.com</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;">Site
                                            Web: <a href="https://pharmaconnect.net">pharmaconnect.net</a></h6>
                                    </td>
                                    <td style="text-align: center;width:30%;margin: 0;padding:0">
                                        <h2 v-if="retourSaved.header != null">Proposition Retour
                                        </h2>
                                        <h2 v-if="retourSaved.header != null">
                                            {{ retourSaved.header.No }}
                                        </h2>
                                    </td>
                                    <td style="text-align: right;width:30%;margin: 0;padding:0">
                                        <h6 style="margin: 0;font-size:medium;font-weight: bold;">{{ new
                                            Date().toLocaleString() }}</h6>
                                        <h6 style="margin: 0;font-size:medium;font-weight: bold;">PHARMACONNECT\PRL</h6>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr class="information">
                        <td>
                            <table>
                                <tr style="margin-top: 20px;margin-bottom: 20px">
                                    <td>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Bon de
                                                Retour N° : </strong>
                                            {{ retourSaved.header.No }}
                                        </h6>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Date
                                                : </strong>
                                            {{ retourSaved.header.Posting_Date.substring(0, 10) }}
                                        </h6>
                                    </td>

                                    <td>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Nom client : </strong>
                                            {{ retourSaved.header.Sell_to_Customer_Name }}
                                        </h6>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>N° client :</strong>
                                            {{ retourSaved.header.Sell_to_Customer_No }}
                                        </h6>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

                <table border="1" cellpadding="1" cellspacing="0" v-if="retourSaved.lines != null">
                    <tr class="heading">
                        <td class="bordered" style="text-align: center;width:7%;font-size: medium;">
                            Code
                        </td>
                        <td class="bordered" style="text-align: center;width:25%;font-size: medium;">
                            Désignation
                        </td>
                        <td class="bordered" style="text-align: center;width:7%;font-size: medium;">
                            Qté
                        </td>
                        <td class="bordered" style="text-align: center;width:15%;font-size: medium;">
                            Prix Unitaire
                        </td>
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium;">
                            BL
                        </td>
                        <td class="bordered" style="text-align: center;width:23%;font-size: medium;">
                            Motif
                        </td>

                    </tr>

                    <tr class="details" :key="indextr" v-for="(tr, indextr) in retourSaved.lines">
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium ">
                            {{ tr.No }}
                        </td>

                        <td class="bordered" style="text-align: left;width:35% ; font-size: medium">
                            {{ tr.Description }}
                        </td>
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium   ">
                            {{ tr.Quantity }}
                        </td>
                        <td class="bordered" style="text-align: center;width:9%;font-size: medium  ">
                            {{ tr.Unit_Price }}
                        </td>
                        <td class="bordered" style="text-align: center;width:9%;font-size: medium  ">
                            {{ tr.N_x00B0__BL }}
                        </td>
                        <td class="bordered" style="text-align: center;width:30%;font-size: medium  ">
                            {{ tr.Reason_Description }}
                        </td>

                    </tr>

                </table>

                <br>

                <br>

                <br>

                <b-row>
                    <b-col cols="4" md="5">
                        <table border="1" cellpadding="0" cellspacing="0">

                            <!-- <img :src="cachet" style="width:250px"> -->

                        </table>
                    </b-col>
                    <b-col cols="2" md="2">
                    </b-col>

                </b-row>

            </div>
        </div>


        <b-modal ref="my-modal" scrollable title="Détail demande retour" ok-only ok-title="Imprimer" size="xl"
            cancel-variant="outline-secondary" @ok="handlePrintClick">

            <b-table :bordered="true" :small="true" :hover="true" :no-border-collapse="true" :items="commandesSelected"
                :fields="fields" />

        </b-modal>

        <b-overlay :show="show" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" :agGrid="agGrid"
                    pageTitle="Liste des demandes retour" @refresh="loadData" @filterClicked="filterClicked" :add="true"
                    @plusClicked="$router.push({ name: 'return-proposal-new' })" @cellDoubleClicked="showDetails" />

            </b-card>
        </b-overlay>


    </div>
</template>
    
<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import AgGridDateFilter from '@/views/components/ag-grid/AgGridDateFilter.vue';
import { formatPrice } from "@/utils/formatting";
import printDocs from "@/utils/printDocs";

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            src_logo: require('@/assets/images/logo/logo.png'),
            retourSaved: {
                lines: []
            },
            AgGridDateFilter: AgGridDateFilter,
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [
                    {
                        headerName: "N°",
                        field: "No",
                    },
                    {
                        headerName: "Date Demande Retour",
                        field: "Posting_Date",
                        valueGetter: function (tr) {
                            return tr.data.Posting_Date.substring(0, 10);
                        },
                    },
                    {
                        headerName: "Montant H.T",
                        field: "Amount",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount);
                        },
                    },
                    {
                        headerName: "Montant T.T.C",
                        field: "Amount_Including_VAT",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount_Including_VAT);
                        },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, icon: "read_more", tooltip: "Consulter les détails" },
                        headerName: '',
                        width: 100
                    },

                ],
                rows: []
            },
            fields: [{
                label: 'Code Article',
                key: 'No',
                sortable: true
            },
            {
                label: 'Désignation article',
                key: 'Description',
                sortable: true
            },
            {
                label: 'Prix',
                key: 'Unit_Price',
                sortable: true
            }, {
                label: 'Quantité',
                key: 'Quantity',
                sortable: true
            }
            ],
            commandesSelected: [],
            show: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        handlePrintClick() {
            // console.log(this.retourSaved);
            this.$nextTick(function () {
                var contents = document.getElementById("printMeRetour").innerHTML;
                printDocs.print(contents);
            });
        },
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.loadData();
        },
        async showDetails(event) {
            this.retourSaved = {
                lines: []
            };
            this.retourSaved.header = event.No == undefined ? event.data : event;
            this.show = !this.show;
            let no = event.No == undefined ? event.data.No : event.No;
            let response = await this.$http.get("sales_return_line/findWhere", {
                params: {
                    Document_No: "'" + no + "'"
                }
            }).catch(() => this.show = false);
            this.commandesSelected = response.data;
            this.commandesSelected.forEach(element => {
                this.retourSaved.lines.push(element);
            });
            this.show = !this.show;
            this.$refs['my-modal'].show();
        },
        async loadData() {
            this.show = true;
            let response = await this.$http.post("sales_return_header/findWhere", this.generateDateFilters());
            this.agGrid.rows = [...response.data].reverse();
            this.show = false;
        },
        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            return filters;
        },
    }
}
</script>
    
<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
    